import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql, useQuery, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"


import HeaderMinimal from "../../components/header/HeaderMinimal"
import MainMenu from "../../components/header/Menu"
import Footer from "../../components/footer/Footer"

import Sidebar from "../../components/major-pieces/sidebar/Sidebar"
import Subheader from "../../components/major-pieces/subheader/Subheader"
import SubMenu from "../../components/major-pieces/subheader/SubMenu"
import PageBlock from "../../components/major-pieces/pageblock/PageBlock"
import ERIPageBlocks from "../../components/major-pieces/pageblock/ERIPageBlocks"
import getPageBlockFromData from "../../components/major-pieces/pageblock/PageBlock"

import "../../styles/global.css"
import "../../styles/global_media.css"
import "../../styles/menu.css"
import "../../styles/school.css"
import "../../styles/header.css"
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import JSONData from "../../../content/school/schoolData.json"

import COVID_LETTER from "../../../static/pdf/school/Covid Protocols  for School 2021-22.pdf"

function ERI () {
	
	
	const query = graphql`
	      query {
	        allFile(
	          sort: { fields: name, order: ASC }
	          filter: { relativePath: { glob: "images/pages/school/*.*" } }
	        ) {
	          edges {
	            node {
	              id
	              name
	              childImageSharp {
	                fluid {
	                  ...GatsbyImageSharpFluid
	                }
	              }
	            }
	          }
	        }
	        
			}
	`



	let data = useStaticQuery(query)
	console.log(data)

	let images = data.allFile
	let links = JSONData.links;
	let subheaderData = JSONData.subheader; 

	
	
	console.log("JSONData for school: ")
	console.log(JSONData)
	console.log("all images")
	console.log(images)

	const mainImage = images.edges.find(element => element.node.name === 'sidebar').node

	const column1 = images.edges.find(element => element.node.name === '1').node
	const column2 = images.edges.find(element => element.node.name === '2').node
	const column3 = images.edges.find(element => element.node.name === '3').node
	const prayer = images.edges.find(element => element.node.name === 'prayer').node
	const community = images.edges.find(element => element.node.name === 'community').node
	const academics = images.edges.find(element => element.node.name === 'academics').node
	const freedom = images.edges.find(element => element.node.name === 'freedom').node

	const safe = images.edges.find(element => element.node.name === 'safe').node
	const respect = images.edges.find(element => element.node.name === 'respect').node

	const intensives = images.edges.find(element => element.node.name === 'intensives').node


	return(
		<div className="site body">
			<HeaderMinimal />
			<MainMenu metadata="sub-page"/>
			<main className="site-content school-colors school">


				<div className = "school-main-area">
					<div className="background-image-wrapper">
						<BackgroundImage
			           		className="school-main-image"
			           		fluid={mainImage.childImageSharp.fluid}
			               	alt="" >
			               	<div className="subheader" style={{paddingTop:"15px"}}>
			   					<h1 className="school-name"> 
					   				<div >Saint John's Orthodox Christian School</div>
				       			</h1>
				       			<a href="https://www.sjocs.org" className="school-link-top" target="_blank" >School Website 
				       				<ArrowForwardIcon/> 
				       			</a>
							</div>
			               	<div className="text-overlay">
								<div className="top-line">Building Foundations </div>
								<div> Celebrating Childhood</div>

							</div>
						</BackgroundImage>
						</div>
						<div className = "core-values">
		               		<h1 >Mission Statement </h1>
		               		<div className="line-wrapper"><hr/></div>
		               		</div>
						<div className="mission">
						It is the <b>mission</b> of Saint John’s School to educate our students so that wherever they go in life they will take with them a solid, early education, and they will carry within themselves a childhood experience of people who loved and cared for them in a way that reflects the values of the Orthodox Christian Faith. 
						</div>

		               	<div className = "core-values">
		               		<h1 >Core Values </h1><div className="line-wrapper"><hr/></div>
		               		
		               		<div className="core-value-grid">
		               		<div className="row">
	               				<div className="core-value-wrapper">
			               			<div className="core-value">
										<h3>Nurturing Community</h3>
										Our administration, our teachers, and our clergy are a close-knit team that support each other and our students. This teamwork makes us a nurturing community that addresses each student personally and gives individual attention to each one’s needs.
<Img className="core-value-image" fluid={community.childImageSharp.fluid} />

			               			</div>
			               		</div>
		               			<div className="core-value-wrapper">
			               			<div className="core-value">
										<h3>Orthodox Christian Ethos</h3>
										The Orthodox Christian Faith inspires us to have an ethos of respect, love, patience, honesty, and forgiveness. Our goal is that others will see God’s love through us. We respect the beliefs of others and do not expect them to adopt our Orthodox Christian Faith.
<Img className="core-value-image" fluid={prayer.childImageSharp.fluid} />
			               			</div>
			               		</div>
			               		<div className="core-value-wrapper">
			               			<div className="core-value">
			               				<h3>Strong Academics</h3>
			               				We want our students to excel in reading, writing, math, science and social studies, which requires that we balance each day with study, imagination, and physical activity. We also want them to learn to think critically and to express their ideas.
			               				<br/>
<Img className="core-value-image" fluid={academics.childImageSharp.fluid} />

			               			</div>
			               		</div>
		               		</div>
		               		<div className="row">
		               			<div className="core-value-wrapper">
			               			<div className="core-value">
										<h3>Freedom, Curiosity, and Creativity</h3>
Our School encourages these traits in our staff and our students, and believes a good education requires a variety of methods, experiences, and settings. 
<Img className="core-value-image" fluid={freedom.childImageSharp.fluid} />

			               			</div>
			               		</div>
		               			<div className="core-value-wrapper">
			               			<div className="core-value">
										<h3>Respect</h3>
Our School expects classrooms to be well-managed and our students to be well-behaved. Respect is shown through kindness, listening to others, and following instructions. Our teachers treat their students with respect and in turn expect the same from them.
<Img className="core-value-image" fluid={respect.childImageSharp.fluid} />

			               			</div>
			               		</div>
			               		<div className="core-value-wrapper">
			               			<div className="core-value">
			               				<h3>Safe Environment</h3>
We want our School to have an environment that is safe, where childhood innocence is protected, without racial, social or economic distinctions.
<Img className="core-value-image" fluid={safe.childImageSharp.fluid} />

			               			</div>
			               		</div>     		
		               		</div>
		               		</div>
		               	</div>
		               
				</div>
					

				
			</main>
			<Footer />		
		</div>
		)
}


export default ERI 
//		               				<Img className="core-value-image" fluid={prayer.childImageSharp.fluid} />
